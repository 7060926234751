import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '../views/LoginView.vue'
import PageNotFound from '@/views/PageNotFound'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView,
    meta: { layout: 'base-layout' }
  },
  {
    path: '/dashboard/catalog',
    name: 'catalog-page',
    meta: { layout: 'dashboard-layout' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/CatalogView.vue')
  },
  {
    path: '/dashboard/catalog/:id',
    name: 'catalog-item-page',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/CatalogView.vue')
  },
  {
    path: '/dashboard/catalog/:id/products',
    name: 'catalog-products-page',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/Products/ProductsView')
  },
  {
    path: '/dashboard/products',
    name: 'products',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/Products/ProductsView.vue')
  },
  {
    path: '/dashboard/products/:id',
    name: 'products-item',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/ProductView.vue')
  },
  {
    path: '/dashboard/clients',
    name: 'client-list',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/Clients/ClientListView')
  },
  {
    path: '/dashboard/clients/create',
    name: 'client-create',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/Clients/ClientCreateView')
  },
  {
    path: '/dashboard/clients/:id/update',
    name: 'client-update',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/Clients/ClientUpdateView')
  },
  // Etalon Orders
  {
    path: '/dashboard/orders/etalon/:section',
    name: 'etalon-order-list',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/Orders/v2/EtalonOrderListView.vue')
  },
  {
    path: '/dashboard/orders/:id',
    name: 'etalon-order-view',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/Orders/v2/EtalonOrderView.vue')
  },
  // INET Orders
  {
    path: '/dashboard/inet-orders',
    name: 'inet-order-list',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/InetOrders/OrderListView')
  },
  {
    path: '/dashboard/inet-orders/:id',
    name: 'inet-order-edit',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/InetOrders/OrderEditView')
  },
  // DROP Orders
  {
    path: '/dashboard/drop-orders',
    name: 'drop-order-list',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/DropOrders/OrderListView')
  },
  {
    path: '/dashboard/drop-orders/:id',
    name: 'drop-order-edit',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/DropOrders/OrderEditView')
  },
  // DROP Clients
  {
    path: '/dashboard/drop-users',
    name: 'drop-users-list',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/DropClients/DropClientListView.vue')
  },
  {
    path: '/dashboard/drop-user/:id',
    name: 'drop-user',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/DropClients/DropClientView.vue'),
    children: [
      {
        path: 'general',
        component: () => import('../views/DropClients/ClientPageComponents/ClientGeneral.vue'),
        meta: { layout: 'dashboard-layout' }
      },
      {
        path: 'balance',
        component: () => import('../views/DropClients/ClientPageComponents/ClientBalance.vue'),
        meta: { layout: 'dashboard-layout' }
      },
      {
        path: 'withdraw',
        component: () => import('../views/DropClients/ClientPageComponents/ClientWithdraws.vue'),
        meta: { layout: 'dashboard-layout' }
      }
    ]
  },
  // DROP Withdraws
  {
    path: '/dashboard/drop-withdraws',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/DropWithdraws/WithdrawListView.vue')
  },
  {
    path: '/dashboard/drop-messages',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/DropMessages/DropMessageListPage.vue')
  },
  // ADMIN
  {
    path: '/dashboard/admin/catalog',
    name: 'admin-categories',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/Admin/CatalogSettingView')
  },
  {
    path: '/dashboard/admin/content',
    name: 'admin-categories',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/Admin/ContentsView')
  },
  {
    path: '/dashboard/admin/article/create',
    name: 'admin-categories',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/Admin/CreateContentView')
  },
  {
    path: '/dashboard/admin/article/:id',
    name: 'admin-categories',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/Admin/EditContentView')
  },
  {
    path: '/dashboard/admin/nova-poshta-senders/:store_name',
    name: 'admin-nova-poshta-senders',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/Admin/NovaPoshtaSenders/SenderListView')
  },
  {
    path: '/dashboard/admin/rozetka-stores',
    name: 'admin-rozetka-stores',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/Admin/RozetkaStores/RozetkaStoreListView.vue')
  },
  {
    path: '/dashboard/admin/payment-contragents/:type',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/Admin/PaymentContragent/PaymentContragentListView.vue')
  },
  {
    path: '/dashboard/admin/cylinder/payment-contragents',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/Admin/CylinderPaymentContragent/CylinderPaymentContragentListView.vue')
  },
  // STATISTICS
  {
    path: '/dashboard/statistics/products',
    name: 'admin-product-statistics',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/Admin/Statistics/ProductStatistics')
  },
  {
    path: '/dashboard/statistics/orders',
    name: 'admin-order-statistics',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/Admin/Statistics/OrderStatistics')
  },
  {
    path: '/dashboard/admin/notification-create',
    name: 'create-notification',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/Notifications/CreateNotificationView')
  },
  {
    path: '/dashboard/admin/catalog-dropship-b2b',
    name: 'admin-categories-drop',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/Admin/DropCatalogSettingView')
  },
  {
    path: '/dashboard/logs',
    name: 'admin-categories-log',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/Logs/LogEventListPage.vue')
  },
  {
    path: '/dashboard/rozetka-logs',
    name: 'admin-rozetka-log',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/Logs/RozetkaLogsPage.vue')
  },
  {
    path: '/test/html',
    name: 'test-html',
    meta: { layout: 'dashboard-layout' },
    component: () => import('../views/Admin/HTMLView')
  },
  {
    path: '*',
    meta: { layout: 'base-layout' },
    component: PageNotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
