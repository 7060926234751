import axios from 'axios'

const baseURL = process.env.VUE_APP_DROP_ORDER_SERVICE + '/api/admin/messages'

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
    getMessages ({ state }, filters) {
      return axios.get(baseURL, {
        params: filters
      })
    },
    createMessage ({ state }, data) {
      return axios.post(baseURL, data)
    },
    updateMessage ({ state }, data) {
      return axios.put(baseURL + '/' + data.id, data)
    },
    sendMessage ({ state }, id) {
      return axios.post(baseURL + '/' + id)
    },
    deleteMessage ({ state }, id) {
      return axios.delete(baseURL + '/' + id)
    }
  }
}
