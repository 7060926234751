<template>
  <v-navigation-drawer
    class="main-bg"
    v-model="show_aside"
    app
    dark
    clipped
    :src="getBackgroundImage()"
    overlay-color="red"
    @input="setShow"
  >
    <v-list
      nav
      dense
    >
      <v-subheader>Меню</v-subheader>
      <v-list-item-group
        active-class="gray--text"
      >
        <v-list-item to="/dashboard/catalog">
          <v-icon class="mr-2">list</v-icon>
          <v-list-item-title>Каталог</v-list-item-title>
        </v-list-item>

        <v-list-item to="/dashboard/products">
          <v-icon class="mr-2">category</v-icon>
          <v-list-item-title>{{ $t('products.label') }}</v-list-item-title>
        </v-list-item>
        <v-list-item to="/dashboard/clients">
          <v-icon class="mr-2">person</v-icon>
          <v-list-item-title>{{ $t('clients.label') }}</v-list-item-title>
        </v-list-item>
        <v-list-item to="/dashboard/orders/etalon/reserves">
          <v-icon class="mr-2">wysiwyg</v-icon>
          <v-list-item-title>{{ $t('orders.label') }}</v-list-item-title>
        </v-list-item>
        <v-list-item to="/dashboard/inet-orders">
          <v-icon class="mr-2">mdi-hat-fedora</v-icon>
          <v-list-item-title>{{ $t('inet-orders.label') }}</v-list-item-title>
        </v-list-item>
        <v-list-group
            no-action
        >
          <template v-slot:activator>
            <v-icon class="mr-2">wysiwyg</v-icon>
            <v-list-item-title>Drop IT</v-list-item-title>
          </template>
          <v-list-item  active-class="white--text" to="/dashboard/drop-orders">
            <v-list-item-title>Заказы</v-list-item-title>
          </v-list-item>
          <v-list-item active-class="white--text" to="/dashboard/drop-users">
            <v-list-item-title>Пользователи</v-list-item-title>
          </v-list-item>
          <v-list-item active-class="white--text" to="/dashboard/drop-withdraws">
            <v-list-item-title>Запросы на вывод</v-list-item-title>
          </v-list-item>
          <v-list-item active-class="white--text" to="/dashboard/drop-messages">
            <v-list-item-title>{{$t('drop_messages.label')}}</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group
          no-action
        >
          <template v-slot:activator>
            <v-icon class="mr-2">query_stats</v-icon>
            <v-list-item-title>Статистика</v-list-item-title>
          </template>
          <v-list-item to="/dashboard/statistics/products">
            <v-list-item-title>Товары</v-list-item-title>
          </v-list-item>
          <v-list-item to="/dashboard/statistics/orders">
            <v-list-item-title>Заказы</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'MainMenuAsideComponent',
  props: ['is_show'],
  data: () => ({
    show_aside: false
  }),
  watch: {
    is_show: {
      immediate: true,
      handler (v) {
        this.show_aside = v
        this.$emit('show-main-menu', v)
      }
    }
  },
  methods: {
    setShow (v) {
      this.$emit('show-main-menu', v)
    },
    getBackgroundImage () {
      const backgroundImages = {
        winter1: 'https://i.pinimg.com/736x/df/81/75/df817542795c72f9f77918d1a9ecd25a.jpg',
        winter2: 'https://i.pinimg.com/736x/06/6f/72/066f724d0b4ed6aa2c3dbdbe2dae8aa9.jpg',
        winter3: 'https://i.pinimg.com/736x/1e/55/1b/1e551b05ddc4941b35e82b9b2128339a.jpg',
        spring1: 'https://i.pinimg.com/736x/38/bf/01/38bf018d5d99f44c8af0c15f67800007.jpg',
        spring2: 'https://i.pinimg.com/736x/0f/12/9f/0f129fb5f9270722f42878dc59b06686.jpg',
        spring3: 'https://i.pinimg.com/736x/3c/fe/74/3cfe74beec1850d1c4454ac0a99b65b4.jpg',
        summer1: 'https://i.pinimg.com/736x/55/6f/3e/556f3e8b82763947b994421b50c3c02f.jpg',
        summer2: 'https://i.pinimg.com/736x/cf/67/5f/cf675ffe7457d0ab44776d1b3383e7d7.jpg',
        summer3: 'https://i.pinimg.com/736x/33/47/cc/3347cc018a9e20d08ce14e4757e60c68.jpg',
        fall1: 'https://i.pinimg.com/736x/a4/39/f6/a439f6e89cecd68bda9b01a5b1548a36.jpg',
        fall2: 'https://i.pinimg.com/736x/49/55/2b/49552bf9d2525db0c3e6f9c6440917ff.jpg',
        fall3: 'https://i.pinimg.com/736x/31/50/97/3150971de984711f1a9042f955b46a9f.jpg',
        new_year: 'https://i.pinimg.com/736x/b4/21/d3/b421d34e0bc6a11bd35f4182be67fbb1.jpg',
        merry: 'https://i.pinimg.com/736x/e2/59/e2/e259e29a7e4024d8e8bb2aab79c5d379.jpg'
      }

      const today = new Date()
      const month = today.getMonth() + 1 // Январь = 0
      const day = today.getDate()

      // Проверка периодов
      // Рождество
      if ((month === 12 && day >= 25) && (month === 12 && day <= 26)) {
        return backgroundImages.merry // Рождество
      }

      if ((month === 12 && day >= 26) || (month === 1 && day <= 15)) {
        return backgroundImages.new_year // Новый год
      }

      switch (month) {
        case 1:
          return backgroundImages.winter2
        case 2:
          return backgroundImages.winter3
        case 3:
          return backgroundImages.spring1
        case 4:
          return backgroundImages.spring2
        case 5:
          return backgroundImages.spring3
        case 6:
          return backgroundImages.summer1
        case 7:
          return backgroundImages.summer2
        case 8:
          return backgroundImages.summer3
        case 9:
          return backgroundImages.fall1
        case 10:
          return backgroundImages.fall2
        case 11:
          return backgroundImages.fall3
        case 12:
          return backgroundImages.winter1
      }

      return backgroundImages.winter1
    }
  }
}
</script>

<style scoped lang="scss">

</style>
