import axios from 'axios'

const baseURL = 'https://api.novaposhta.ua/v2.0/json/'

export default {
  namespaced: true,
  state: {
    searchCity: null,
    searchBranch: null,
    searchAddress: null,
    cities: [],
    branches: [],
    addresses: []
  },
  mutations: {
    setSearchCity (state, v) {
      state.searchCity = v
    },
    setSearchBranch (state, v) {
      state.searchBranch = v
    },
    setSearchAddress (state, v) {
      state.searchAddress = v
    },
    setCities (state, payload) {
      state.cities = payload
    },
    setBranches (state, payload) {
      state.branches = payload
    },
    setAddresses (state, payload) {
      state.addresses = payload
    }
  },
  actions: {
    getCities ({ state, commit }) {
      const instance = axios.create()
      delete instance.defaults.headers

      const data = {
        modelName: 'Address',
        calledMethod: 'getCities',
        methodProperties: {
          FindByString: state.searchCity,
          Limit: '10',
          Page: '1'
        }
      }

      instance.post(baseURL, data).then(response => {
        commit('setCities', response.data.data)
      })
    },
    getBranches ({ state, commit }, cityRef) {
      const instance = axios.create()
      delete instance.defaults.headers

      const data = {
        modelName: 'AddressGeneral',
        calledMethod: 'getWarehouses',
        methodProperties: {
          CityRef: cityRef,
          FindByString: state.searchBranch,
          Page: '1',
          Limit: '30'
        }
      }

      instance.post(baseURL, data).then(response => {
        commit('setBranches', response.data.data)
      }).catch(e => {
      })
    },
    getAddress ({ state, commit }, cityRef) {
      const instance = axios.create()
      delete instance.defaults.headers
      const requestData = {
        modelName: 'Address',
        calledMethod: 'getStreet',
        methodProperties: {
          CityRef: cityRef,
          FindByString: state.searchAddress,
          Page: '1',
          Limit: '5'
        }
      }
      instance.post(baseURL, requestData).then(response => {
        commit('setAddresses', response.data.data)
      })
    },
    getCounterparty ({ state, commit }, edrpou) {
      const instance = axios.create()
      delete instance.defaults.headers
      const requestData = {
        modelName: 'Counterparty',
        calledMethod: 'save',
        apiKey: '78f19724b096bb6d5f6a0c19b1541c0a',
        methodProperties: {
          CounterpartyType: 'Organization',
          EDRPOU: edrpou,
          CounterpartyProperty: 'Recipient'
        }
      }
      return instance.post(baseURL, requestData)
    }
  }
}
