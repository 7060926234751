import axios from 'axios'

const baseURL = process.env.VUE_APP_DROP_ORDER_SERVICE + '/api/admin/orders'

export default {
  namespaced: true,
  state: {
    order: null
  },
  mutations: {
    setOrder (state, payload) {
      state.order = payload
    }
  },
  actions: {
    async getOrders ({
      state,
      commit
    }, filters) {
      switch (parseInt(filters.sort)) {
        case 1:
          filters.order_by = 'created_at'
          filters.order_direction = 'asc'
          break
        case 2:
          filters.order_by = 'created_at'
          filters.order_direction = 'desc'
          break
      }
      return axios.get(baseURL, {
        params: filters
      })
    },
    async getOrder ({
      state,
      commit
    }, id) {
      return axios.get(baseURL + '/' + id)
    },
    async updateOrder ({ state, commit }, data) {
      return axios.put(baseURL + '/' + data.id, data)
    },
    async updateOrderStatuses ({ state, commit }, data) {
      return axios.put(baseURL + '/' + data.id + '/statuses', data)
    },
    async createEN ({ state, commit }, id) {
      return axios.post(baseURL + '/' + id + '/create-en')
    },
    async tracking ({ state, commit }, id) {
      return axios.post(baseURL + '/' + id + '/tracking')
    },
    async cancelOrder ({ state, commit }, data) {
      return axios.delete(baseURL + '/' + data.id, {
        data: data
      })
    },
    async updateProducts ({ state }, data) {
      return axios.put(baseURL + '/' + data.order_id + '/update-products', data)
    }
  }
}
